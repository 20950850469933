<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.home") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 flex h-full flex-col md:col-span-6">
        <h2 class="mb-2">{{ t("headings.latest_bulletins") }}</h2>
        <div class="card h-full">
          <custom-table
            :ascending="false"
            :fields="bulletinFields"
            :items="bulletins"
            :loading="waiting"
            :pagination="false"
            :perPage="10"
            :searchable="false"
            :searchFields="bulletinSearchFields"
            :showHeader="true"
            sortBy="createdAt"
            :sortFields="bulletinSortFields"
            @row-click="(id) => router.push(`/bulletins/${id}`)"
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="() => router.push('/notifications')"
                  id="header-notifications-button"
                  label="all_notifications"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(createdAt)="{ value }">
              <span>{{ getLocaleDate(locale, value) }}</span>
            </template>
          </custom-table>
        </div>
      </div>
      <div class="col-span-12 flex h-full flex-col md:col-span-6">
        <h2 class="mb-2">{{ t("headings.latest_notifications") }}</h2>
        <div class="card h-full">
          <custom-table
            :ascending="false"
            :fields="notificationFields"
            :items="notifications"
            :loading="waiting"
            :pagination="false"
            :perPage="10"
            :searchable="false"
            :searchFields="notificationSearchFields"
            :showHeader="true"
            sortBy="createdAt"
            :sortFields="notificationSortFields"
            @row-click="(id) => router.push(`/notifications/${id}`)"
          >
            <template #header>
              <span class="flex justify-end"
                ><form-button
                  :command="() => router.push('/bulletins')"
                  id="header-bulletins-button"
                  label="all_bulletins"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
              /></span>
            </template>
            <template #cell(createdAt)="{ value }">
              <span>{{ getLocaleDate(locale, value) }}</span>
            </template>
          </custom-table>
        </div>
      </div>
      <div class="col-span-12 flex h-full flex-col">
        <h2 class="mb-2">{{ t("headings.invitations") }}</h2>
        <div class="card h-full">
          <custom-table
            :ascending="false"
            :fields="invitationFields"
            :items="invitations"
            :loading="waiting"
            :pagination="true"
            :perPage="10"
            :searchable="false"
            :showHeader="false"
            sortBy="createdAt"
            :sortFields="invitationSortFields"
            @row-click="(id) => router.push(`/invitation/${id}`)"
          >
            <template #cell(createdAt)="{ value }">
              <span>{{ getLocaleDate(locale, value) }}</span>
            </template>
            <template #cell(roleId)="{ value }">
              <span>{{ t("roles." + getRoleName(value, roles)) }}</span>
            </template>
            <template #cell(actions)="{ item }">
              <div class="flex justify-end gap-2">
                <form-button
                  :command="() => accept(item.id)"
                  :id="`accept-invitation-${item.id}-button`"
                  label="accept"
                  :textVariant="company.primaryText"
                  type="button"
                  :variant="company.primary"
                />
                <form-button
                  :command="() => reject(item.id)"
                  :id="`reject-invitation-${item.id}-button`"
                  label="reject"
                  textVariant="light"
                  type="button"
                  variant="danger"
                />
              </div>
            </template>
          </custom-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getRoleName } from "../utils/roleUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const bulletins = computed(() => store.state.bulletin.latest);
    const company = computed(() => store.state.company.company);
    const invitations = computed(() => store.state.invitation.invitations);
    const notifications = computed(() => store.state.notification.latest);
    const roles = computed(() => store.state.role.roles);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.notification.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const bulletinFields = ref([
      {
        key: "topic",
        label: "topic",
      },
      {
        key: "createdAt",
        label: "date",
      },
    ]);
    const bulletinSearchFields = ref([]);
    const bulletinSortFields = ref(["topic", "createdAt"]);

    const invitationFields = ref([
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "estateName",
        label: "estate",
      },
      {
        key: "apartment",
        label: "apartment",
      },
      {
        key: "roleId",
        label: "role",
      },
      {
        key: "companyName",
        label: "estate_manager",
      },
      {
        key: "actions",
        label: "empty",
      },
    ]);
    const invitationSearchFields = ref([]);
    const invitationSortFields = ref(["type", "createdAt"]);

    const notificationFields = ref([
      {
        key: "type",
        label: "type",
      },
      {
        key: "createdAt",
        label: "date",
      },
    ]);
    const notificationSearchFields = ref([]);
    const notificationSortFields = ref(["type", "createdAt"]);

    onMounted(() => {
      store.dispatch("bulletin/getLatestBulletins", user.value.currentEstate);
      store.dispatch("invitation/getInvitations", user.value.id);
      store.dispatch("notification/getLatestNotifications", {
        estateId: user.value.currentEstate,
        userId: user.value.id,
      });
      store.dispatch("role/getRoles");
    });

    return {
      accept: (id) => store.dispatch("invitation/accept", id),
      bulletins,
      bulletinFields,
      bulletinSearchFields,
      bulletinSortFields,
      company,
      getLocaleDate,
      getRoleName,
      invitationFields,
      invitations,
      invitationSearchFields,
      invitationSortFields,
      locale,
      notifications,
      notificationFields,
      notificationSearchFields,
      notificationSortFields,
      reject: (id) => store.dispatch("invitation/reject", id),
      roles,
      router,
      t,
      waiting,
    };
  },
};
</script>
